export const FirebaseConfig = {
	"projectId": "yt-cabs",
	"appId": "1:1051480934474:web:034e170ed6bf124cdfbeb3",
	"databaseURL": "https://yt-cabs-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "yt-cabs.appspot.com",
	"locationId": "asia-southeast1",
	"apiKey": "AIzaSyB0mzcR2I133oibIEQe8kdcONgNmuPl5U0",
	"authDomain": "yt-cabs.firebaseapp.com",
	"messagingSenderId": "1051480934474",
	"measurementId": "G-SJP6786Z3Z"
};